
import React, { useState } from "react";
import { useLocation } from 'react-router';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import DataTable from 'react-data-table-component';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';

import 'react-tabs/style/react-tabs.css';
import '../App.css';

const dataColumns = [
	{
		name: 'Select the Additional Information Nedded',
		selector: row => row.title,
	},
];

const docColumns = [
	{
		name: 'Select the Documents to Request',
		selector: row => row.title,
	},
];

const dataData = [
  	{
		id: 1,
		title: 'Data Element 1',
        type:  'text',
	},
	{
		id: 2,
		title: 'Data Element 2',
        type:  'date',
	},
    {
		id: 3,
		title: 'Data Element 3',
        type:  'paragraph',
	},
    {
		id: 4,
		title: 'Data Element 4',
        type:  'select',
	},
]

const docData = [
    {
      id: 1,
      title: 'Document 1',
      type:  'file',
  },
  {
      id: 2,
      title: 'Document 2',
      type:  'file',
  },
  {
      id: 3,
      title: 'Document 3',
      type:  'file',
  },
  {
      id: 4,
      title: 'Document 4',
      type:  'file',
  },
]

const Input = ( props ) => {

    return (
        <>
            {(() => {
                switch (props.props.type) {
                    case 'select':
                        return <div className="App-FormInput"><label>{props.props.title}:</label><select style={{order:props.props.order}} ><option>please select an option</option></select></div>
                    case 'paragraph':
                        return <div className="App-FormTextArea"><label>{props.props.title}:</label><textarea></textarea></div>
                    default:                        
                        return <div className="App-FormInput"><label>{props.props.title}:</label><input type={props.props.type} style={{order:props.props.order}} /></div>
                }
            })()}
        </>
    )
};
 
const Create = () => {

    const submissionNumber = useLocation().pathname.split("/")[2];
    const [dataList, setDataList] = useState([]);
    const [docList, setDocList] = useState([]);
    const inputs = [];
    
    const handleDataChange = ({ selectedRows }) => {
        selectedRows.forEach(function(data, index) {
            let props = {
                title:data.title,
                order:index,
                type:data.type
            }
            inputs.push(<Input props={props} />)
        });
        setDataList(inputs)
        
      };

      const handleDocChange = ({ selectedRows }) => {
        selectedRows.forEach(function(data, index) {
            let props = {
                title:data.title,
                order:index,
                type:data.type
                }
            inputs.push(<Input props={props} />)
        });
        setDocList(inputs)
        
      };
         
    return (
        <div className='App'>
            <div className='App-header'>
                <button>Send Request</button>
            </div>
            <div className='App-Content'>
                <div className='App-DataElements'>
                    <Tabs forceRenderTabPanel={true}>
                        <TabList>
                            <Tab>Request</Tab>
                            <Tab>Additional Info</Tab>
                            <Tab>Documents</Tab>
                        </TabList>
                        <TabPanel>
                            <Card className="App-Card">
                                <div className='App-Input'>
                                    <label>Recipient Email Address:</label>
                                    <input type='email' />
                                </div>
                                <div className='App-Input'>
                                    <label>Request Expiration Date:</label>
                                    <input type='date' />
                                </div>
                                <div className='App-Input'>
                                    <label>Create Reminder Activity;</label>
                                    <Switch />
                                </div>
                                <div className='App-Input'>
                                    <label>Create Request Completed Activity:</label>
                                    <Switch />
                                </div>
                                <div className='App-Input'>
                                    <label>Create Policy File Note:</label>
                                    <Switch />
                                </div>
                            </Card>
                        </TabPanel>
                        <TabPanel>
                            <Card className="App-Card">
                                <DataTable
                                    columns={dataColumns}
                                    data={dataData}
                                    defaultSortFieldId={0}
                                    selectableRows
                                    onSelectedRowsChange={handleDataChange}
                                    selectableRowsComponent={Checkbox}
                                />
                            </Card>
                        </TabPanel>
                        <TabPanel>
                            <Card className="App-Card">
                                <DataTable
                                    columns={docColumns}
                                    data={docData}
                                    defaultSortFieldId={0}
                                    selectableRows
                                    onSelectedRowsChange={handleDocChange}
                                    selectableRowsComponent={Checkbox}
                                />
                            </Card>
                        </TabPanel>
                    </Tabs>
                </div>
                <div className='App-Form'>
                    <div className="App-FormTitle">Form Preview</div>
                    <Card className='App-FormPreview'>
                        <div className="App-FormHeader">
                            <h3>Submission: {submissionNumber}</h3>
                            <h2>Applicant Name</h2>
                        </div>
                        <div className='App-TitleBar'>Please Provide the Following Information</div>
                        {dataList}
                        <div className='App-TitleBar'>Please Upload the Following Documents</div>
                        {docList}
                    </Card>
                </div>
            </div>
        </div>
    )
  };
  
  export default Create;