
import React, { useState, useEffect } from "react";

import 'react-tabs/style/react-tabs.css';
import '../App.css';

const oData = 
    {
        submission: '987654',
        account: 'Company ABC',
        elements: [
            {
                id: 1,
                title: 'Data Element 1',
                type:  'text',
            },
            {
                id: 2,
                title: 'Data Element 2',
                type:  'date',
            },
            {
                id: 3,
                title: 'Document 1',
                type:  'file',
            },
            {
                id: 4,
                title: 'Document 2',
                type:  'file',
            },
        ],
    }


const Input = ( props ) => {

    return (
        <>
            {(() => {
                switch (props.props.type) {
                    case 'select':
                        return <div className="App-FormInput"><label>{props.props.title}:</label><select style={{order:props.props.order}} ><option>please select an option</option></select></div>
                    case 'paragraph':
                        return <div className="App-FormTextArea"><label>{props.props.title}:</label><textarea></textarea></div>
                    default:                        
                        return <div className="App-FormInput"><label>{props.props.title}:</label><input type={props.props.type} style={{order:props.props.order}} /></div>
                }
            })()}
        </>
    )
};
 
const Form = () => {

    const [dataList, setDataList] = useState([]);
    const [docList, setDocList] = useState([]);

    useEffect(() => {
        oData.elements.forEach(function(data, index) {
            let props = {
                title:data.title,
                order:index,
                type:data.type
            }
            if ( data.type === 'file' ) {
               // setDocList( ...docList, <Input props={props} /> )
                setDocList(docList => [...docList, <Input props={props} />]);
            } else {
              //  setDataList( ...dataList, <Input props={props} /> )
               setDataList(dataList => [...dataList, <Input props={props} />]);
            }
        });
      }, []);
      
    return (
        <div className='App'>
            <div className='App-header'>
                <button>Save</button>
                <button>Submit</button>
            </div>
            <div className='App-Content'>
                <div className='App-Form'>
                    <div className="App-FormHeader">
                        <h3>Submission: {oData.submission}</h3>
                        <h2>{oData.account}</h2>
                    </div>
                    <div className='App-TitleBar'>Please Provide the Following Information</div>
                    {dataList}
                    <div className='App-TitleBar'>Please Upload the Following Documents</div>
                    {docList}
                </div>
            </div>
        </div>
    )
  };
  
  export default Form;