
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Create from "./pages/Create";
import Form from "./pages/Form";

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/create/:id" element={<Create />}></Route>
        <Route path="/form/:id" element={<Form />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <MyRoutes />
  );
}

export default App;
